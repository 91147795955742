import axios from "axios";
import Cookies from "js-cookie";
import router from "@/router";

const API_URL = process.env.VUE_APP_API_URL;

// Create an Axios instance
export const api = axios.create({
  baseURL: API_URL + "/api/v1",
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const onRequest = (config) => {
  // If http method is `post | put | delete` and XSRF-TOKEN cookie is
  // not present, call '/sanctum/csrf-cookie' to set CSRF token, then
  // proceed with the initial response
  if (
    (config.method == "post" ||
      config.method == "put" ||
      config.method == "delete") &&
    !Cookies.get("XSRF-TOKEN")
  ) {
    return setCSRFToken().then(() => config);
  }
  return config;
};

const setCSRFToken = () => {
  return api.get(`/sanctum/csrf-cookie`);
};

api.interceptors.request.use(onRequest, null);

// Response interceptor to handle 401 Unauthorized
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (!error.response) {
      alert(
        "Serveris neveikia. Bandykite dar kartą arba susisiekite su administratoriumi."
      );
      return;
    }
    if (error.response.status === 419) {
      await setCSRFToken();
      return api.request(error.config);
    }
    if (error.response.status === 500) {
      router.push({ name: "500" });
    }
    if (
      error.response.status === 401 &&
      router.currentRoute.value.name !== "login" &&
      router.currentRoute.value.name !== "404" &&
      router.currentRoute.value.name !== "500"
    ) {
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);

export default api;
