import { useAuthStore } from "../state/auth-store";
import loggedInRoutes from "./loggedInRoutes";
import authRoutes from "./authRoutes";

export default [
  {
    path: "/",
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        try {
          if (
            routeFrom.name === null ||
            routeTo.params.reload ||
            routeFrom.params.reload ||
            routeFrom.name === undefined
          ) {
            await useAuthStore().checkAuth();
            if (!useAuthStore().currentUser.temp_password_changed) {
              next({
                name: "create-pwd-basic",
                query: {
                  temp_password_reset: true,
                  email: useAuthStore().currentUser.email,
                },
              });
            }
          }
        } catch (error) {
          next({ name: "login" });
        }
      },
    },
    children: loggedInRoutes,
    component: () => import("../layouts/index.vue"),
  },
  {
    path: "/auth",
    component: () => import("../layouts/auth.vue"),
    children: authRoutes,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
