import { useAuthStore } from "../state/auth-store";
export default [
  {
    path: "login",
    name: "login",
    component: () => import("../views/account/login.vue"),
  },
  {
    path: "forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
  },
  {
    path: "logout",
    name: "logout",
    meta: {
      title: "Logout",
      beforeResolve(routeTo, routeFrom, next) {
        next();
        useAuthStore().logout();
      },
    },
    component: () => import("../views/auth/logout/basic"),
  },

  // {
  //   path: "register",
  //   name: "signup-basic",
  //   meta: {
  //     title: "Signup Basic",
  //     authRequired: true,
  //   },
  //   component: () => import("../views/auth/signup/basic"),
  // },
  {
    path: "reset-password",
    name: "create-pwd-basic",
    meta: {
      title: "Create Password",
    },
    component: () => import("../views/auth/create/basic"),
  },
  {
    path: "/404",
    name: "404",
    meta: {
      title: "Error 404",
      async beforeResolve() {
        try {
          await useAuthStore().checkAuth();
        } catch (error) {
          console.log(error);
        }
      },
    },
    component: () => import("../views/auth/errors/404"),
  },
  {
    path: "/500",
    name: "500",
    meta: {
      title: "Error 500",
    },
    component: () => import("../views/auth/errors/500"),
  },
];
