import { createWebHistory, createRouter, START_LOCATION } from "vue-router";
// import { useAuthStore } from "../state/auth-store";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (
    from === START_LOCATION &&
    to.path !== "/" &&
    !to.path.startsWith("/auth") &&
    !to.path.startsWith("/404") &&
    !to.path.startsWith("/500")
  ) {
    next("/");
  } else {
    next();
  }
});

router.beforeResolve(async (to, from, next) => {
  try {
    // For each matched route...
    for (const route of to.matched) {
      if (route.meta && route.meta.beforeResolve) {
        await route.meta.beforeResolve(to, from, next);
      } else {
        next();
      }
    }
  } catch (error) {
    // Handle errors or redirection if necessary
    if (error.message === "Redirected") {
      next(error.redirectTo);
    } else {
      console.error(error);
      next(false);
    }
  }
});

export default router;
