import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import pinia from "./state/store";

import BootstrapVueNext from "bootstrap-vue-next";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import { vMaska } from "maska";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueFeather from "vue-feather";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import "@/assets/scss/config/default/app.scss";

import "@/assets/scss/mermaid.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

const app = createApp(App);

app
  .use(pinia)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVueNext)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      language: "lt",
    },
  })
  .use(ToastPlugin)
  .component(VueFeather.type, VueFeather)
  .component("VueDatePicker", VueDatePicker)
  .directive("maska", vMaska)
  .use(vClickOutside)
  .mount("#app");
