import { api } from "../axiosService";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    currentUser: null,
    loading: false,
  }),
  getters: {
    loggedIn: (state) => !!state.currentUser,
  },

  actions: {
    async checkAuth() {
      // checks if http-cookie session is still valid:
      this.loading = true;
      try {
        const response = await api.get("/auth/check");
        this.currentUser = response.data.data.user;
        return response.data.data.authenticated;
      } catch (error) {
        return false;
      } finally {
        this.loading = false;
      }
    },

    async login(email, password) {
      try {
        const response = await api.post("/auth/login", { email, password });
        this.currentUser = response.data.data.user;
        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },

    async logout() {
      try {
        await api.post("/auth/logout");
        this.currentUser = null;
      } catch (error) {
        console.log(error);
      }
    },

    async sendResetLink(email) {
      return api.post("/auth/send-reset", { email });
    },

    async resetPassword(
      email,
      token,
      password,
      password_confirmation,
      is_temp_password = false
    ) {
      return api.post("/auth/reset", {
        email,
        token,
        password,
        password_confirmation,
        is_temp_password,
      });
    },

    async sendEmailChangeLink(email) {
      return api.post("/auth/send-email-change", { email });
    },

    async changeEmail(email, code) {
      return api.post("/auth/change-email", { email, code });
    },
  },
});
