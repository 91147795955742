export default [
  {
    name: "index",
    path: "/",
    component: () => import("../views/pages/etapai.vue"),
    meta: {
      title: "Etapai",
    },
    breadcrumb: [{ name: "Etapai", path: "/" }],
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("../views/pages/profile.vue"),
    meta: {
      title: "Profilis",
      breadcrumb: [{ name: "Profilis", path: "/profile" }],
    },
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("../views/pages/contacts.vue"),
    meta: {
      title: "Kontaktai",
      breadcrumb: [{ name: "Kontaktai", path: "/contacts" }],
    },
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("../views/pages/FAQ.vue"),
    meta: {
      title: "D.U.K.",
      breadcrumb: [{ name: "D.U.K.", path: "/faq" }],
    },
  },
  {
    name: "warranty",
    path: "/warranty",
    component: () => import("../views/pages/warranty.vue"),
    meta: {
      title: "Garantija",
      breadcrumb: [{ name: "Garantija", path: "/warranty" }],
    },
  },
  {
    name: "news",
    path: "/news",
    component: () => import("../views/pages/news.vue"),
    meta: {
      title: "Naujienos",
      breadcrumb: [{ name: "Naujienos", path: "/news" }],
    },
  },
  {
    name: "newsDetails",
    path: "/news/:id",
    component: () => import("../views/pages/newsDetails.vue"),
    meta: {
      title: "Naujienos",
      breadcrumb: [
        { name: "Naujienos", path: "/news" },
        { name: "Naujiena", path: "/news/:id" },
      ],
    },
  },
  {
    name: "preliminari",
    path: "/etapai/preliminari",
    component: () => import("../views/pages/preliminari.vue"),
    meta: {
      title: "Preliminari sutartis",
      breadcrumb: [
        { name: "Etapai", path: "/" },
        { name: "Preliminari sutartis", path: "/preliminari" },
      ],
    },
  },
  {
    name: "avansas",
    path: "/etapai/avansas",
    component: () => import("../views/pages/avansas.vue"),
    meta: {
      title: "Apmokėtas avanso mokestis",
      breadcrumb: [
        { name: "Etapai", path: "/" },
        { name: "Avanso apmokėjimas", path: "/preliminari" },
      ],
    },
  },
  {
    name: "paskola",
    path: "/etapai/paskola",
    component: () => import("../views/pages/paskola.vue"),
    meta: {
      title: "Paskola",
      breadcrumb: [
        { name: "Etapai", path: "/" },
        { name: "Paskola", path: "/paskola" },
      ],
    },
  },
  {
    name: "mokejimai",
    path: "/mokejimai",
    component: () => import("../views/pages/avansas.vue"),
    meta: {
      title: "Mokėjimai",
      breadcrumb: [{ name: "Mokėjimai", path: "/mokejimai" }],
    },
  },
  {
    name: "propertyDocuments",
    path: "/property-documents",
    component: () => import("../views/pages/propertyDocuments.vue"),
    meta: {
      title: "Nekilnojamojo turto dokumentai",
      breadcrumb: [{ name: "NT dokumentai", path: "/property-documents" }],
    },
  },
  {
    name: "propertyDocumentsSingle",
    path: "/property-documents/:id",
    component: () => import("../views/pages/propertyDocumentsSingle.vue"),
    meta: {
      title: "Nekilnojamojo turto dokumentai",
      breadcrumb: [
        { name: "NT dokumentai", path: "/property-documents" },
        { name: "Dokumentų sarąšas", path: "/property-documents/:id" },
      ],
    },
  },
  {
    name: "turtoApziura",
    path: "/etapai/turto-apziura",
    component: () => import("../views/pages/turtoApziura.vue"),
    meta: {
      title: "Turto apžiūra",
      breadcrumb: [
        { name: "Etapai", path: "/" },
        { name: "Turto apžiūra", path: "/turto-apziura" },
      ],
    },
  },
  {
    name: "notarineSutartis",
    path: "/etapai/notarine-sutartis",
    component: () => import("../views/pages/notarineSutartis.vue"),
    meta: {
      title: "Notarinė sutartis",
      breadcrumb: [
        { name: "Etapai", path: "/" },
        { name: "Notarinė sutartis", path: "/notarine-sutartis" },
      ],
    },
  },
];
