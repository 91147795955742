<template>
  <div class="loader-container">
    <div
      class="spinner-grow text-primary ms-3"
      role="status"
      v-for="index in 3"
      :key="index"
      :class="`delay-${index}`"
    ></div>
  </div>
</template>

<style scoped>
@keyframes appearThenDisappear {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  20%,
  70% {
    opacity: 1;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 20vh;
  left: -3vh;
  right: 0;
  bottom: 0;
  margin: auto;
}

.spinner-grow {
  opacity: 0;
  animation: appearThenDisappear 2s linear infinite;
}

.delay-1 {
  animation-delay: 0s;
}

.delay-2 {
  animation-delay: 0.25s;
}

.delay-3 {
  animation-delay: 0.5s;
}
</style>
