<template>
  <div v-if="authStore.loading" class="spinner-container">
    <realcoSpinner />
  </div>
  <router-view></router-view>
</template>

<script>
import { useAuthStore } from "./state/auth-store";
import realcoSpinner from "./components/realcoSpinner.vue";
export default {
  name: "App",
  components: {
    realcoSpinner,
  },
  setup() {
    const authStore = useAuthStore();
    return { authStore };
  },
};
</script>
